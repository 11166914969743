// Generated by Framer (4b166d5)

import * as React from "react";
import { motion, LayoutGroup } from "framer-motion";
import { Image, transformTemplate, useActiveVariantCallback, useOnAppear, addFonts, withCSS, withGeneratedLayoutId, withStyleAppearEffect } from "framer";
const Frame = withGeneratedLayoutId(motion.div);
const ImageWithGeneratedLayoutId = withGeneratedLayoutId(Image);
const FrameWithStyleAppearEffect = withStyleAppearEffect(withGeneratedLayoutId(motion.div));

const Screen = React.forwardRef(function({ style, ...restProps }, ref) {

return (<div className="framer-kOphP" style={{"display": "contents", "pointerEvents": "auto"}}>
<FrameWithStyleAppearEffect className="framer-1bzw5v9" style={{...style}} name="Vibes" layoutIdKey="oS7vBUXpg" duplicatedFrom={["QQT93TXnZ"]}  background={null} data-framer-name="Vibes" __framer__threshold={0.5} __framer__animateOnce={true} __framer__enter={{"x": 0, "y": 0, "scale": 1, "opacity": 0}} __framer__exit={{"x": 0, "y": 0, "scale": 1, "opacity": 0, "transition": {"type": "tween", "duration": 1, "ease": [0, 0, 1, 1], "delay": 0}}} __framer__animate={{"x": 0, "y": 0, "scale": 1, "opacity": 1, "rotate": 0, "rotateX": 0, "rotateY": 0, "transition": {"type": "tween", "duration": 1, "ease": [0, 0, 1, 1], "delay": 0}}}><Frame className="framer-1g09g7n" style={{"background": "radial-gradient(21.099999999999998% 44.5% at 49.8% 44.7%, hsl(236, 69%, 47%) 0%, hsl(0, 0%, 0%) 100%)"}} name="Noise" layoutIdKey="vRm8i5lG3" duplicatedFrom={["FT7mfseRm", "VbqfTOEAW", "G35xghxD5", "ngXZCueq6"]}  data-framer-name="Noise"/><ImageWithGeneratedLayoutId className="framer-1pfbc9c" style={{"WebkitFilter": "blur(100px)", "filter": "blur(100px)", "mixBlendMode": "color"}} name="telegram_cloud_photo_size_4_5828138705502713516_y" layoutIdKey="x6TxBlFsv" duplicatedFrom={["rV5lRYh3T"]}  background={{"src": new URL("assets/1024/Zd9dUnZu0KwSCYHNnMuaHIFNNaU.jpg", import.meta.url).href, "srcSet": `${new URL("assets/512/Zd9dUnZu0KwSCYHNnMuaHIFNNaU.jpg", import.meta.url).href} 512w, ${new URL("assets/1024/Zd9dUnZu0KwSCYHNnMuaHIFNNaU.jpg", import.meta.url).href} 1024w, ${new URL("assets/Zd9dUnZu0KwSCYHNnMuaHIFNNaU.jpg", import.meta.url).href} 1280w`, "sizes": "2545px", "pixelWidth": 1280, "pixelHeight": 853, "intrinsicWidth": 640, "intrinsicHeight": 426.5, "fit": "fill"}} data-framer-name="telegram_cloud_photo_size_4_5828138705502713516_y" alt=""/></FrameWithStyleAppearEffect>
</div>)

});

const css = [".framer-kOphP [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none;}", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-kOphP .framer-1bzw5v9 { position: relative; overflow: hidden; width: 1400px; height: 980px; }", ".framer-kOphP .framer-1g09g7n { position: absolute; overflow: visible; width: 2547px; height: 1200px; left: calc(49.979347377116916% - 2547px/2); top: calc(53.24074074074077% - 1200px/2); flex: none; }", ".framer-kOphP .framer-1pfbc9c { position: absolute; overflow: visible; width: 2545px; height: 863px; left: calc(49.97885835095139% - 2545px/2); top: calc(50.00000000000002% - 863px/2); flex: none; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 980
 * @framerIntrinsicWidth 1400
 * @framerSupportedLayoutWidth fixed
 * @framerSupportedLayoutHeight fixed
 * @framerScreen
 */
const FramerScreen1bzw5v9: React.ComponentType = withCSS(Screen, css);
export default FramerScreen1bzw5v9;

FramerScreen1bzw5v9.displayName = "Screens/Vibes";

FramerScreen1bzw5v9.defaultProps = {"width": 1400, "height": 980};

addFonts(FramerScreen1bzw5v9, []);